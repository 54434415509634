@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: dejavuesans;
  src: url(../public/font/DejaVueSans.ttf);
}

body {
  margin: 0;
  font-family: dejavuesans, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #000;
  overscroll-behavior: contain;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #000;
}

@media only screen and (max-width: 600px) {
  .desktop {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  .mobile-only {
    display: none !important;
  }
}

.logo {
  position: absolute;
  top: 10px;
  left: 5%;
  width: 7vw;
  transform: translate3d(-50%, 0, 0);
  &.mobile {
    top: 5vw;
    left: 5vw;
    width: 25vw;
    transform: translate3d(0, 0, 0);
  }
}
.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  border: 3px solid white;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*** Intro **/
.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  z-index: 9999;
  color: #fff;
  opacity: 1;
  &.bg {
    background: url("../public/images/intro/intro.jpg");
    background-position: center;
    background-size: cover;
    .greybg {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &.clicked {
    opacity: 0;
    pointer-events: none;
  }
  .centerDiv {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 338px;
    margin-left: -169px;
  }
  &.mobile .centerDiv {
    top: 35%;
  }
  .logointroback {
    //
  }
  .overflowlogofront {
    position: absolute;
    overflow: hidden;
    top: 0px;
  }
  .logointrofront {
    position: relative;
    //
  }
  .vertical-center {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  &.mobile .enterbutton {
    top: 60px;
  }
  .enterbutton {
    top: 200px;
    width: 200px;
    height: 50px;
    position: absolute;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.5s linear;
  }
  .enterbutton.notHover {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
  }
  .enterbutton.isHover {
    background: #fff;
    border: 1px solid #fff;
    color: #000;
    font-weight: bold;
  }
  .textBottom {
    text-align: center;
    color: #fff;
    position: absolute;
    bottom: 5em;
  }
  &.mobile {
    .textBottom {
      margin: 30px;
      line-height: 30px;
    }
  }
  .mentionlink {
    position: absolute;
    bottom: 40px;
    left: 40px;
    color: #fff;
  }
  .dropdown {
    position: absolute;
    bottom: 25px;
    right: 40px;
    button {
      font-size: 20px;
      width: 45px;
      height: 45px;
      background: transparent;
      border: none;
      cursor: pointer;
      color: #fff;
    }
    .mainButton {
    }
    .menu {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      transition: max-height 1s;
      max-height: 0px;
      overflow: hidden;
      li {
        height: 10px;
        transition: height 1s;
        button {
          color: #000;
          &:hover {
            color: #fff;
          }
        }
      }
      &.fade-in {
        max-height: 300px;
        li {
          height: 40px;
        }
      }
    }
  }

  &.prescreen {
    background: rgba(0, 0, 0, 0.3);
    .contentflex {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: normal;
      align-items: center;
      align-content: center;
      &.mobile {
        flex-direction: column;
        .barre {
          rotate: 90deg;
        }
      }
    }
    .item {
      display: block;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;
      align-self: auto;
      text-align: center;
      width: 220px;
      margin: 0 50px;
      .blockImage {
        width: 100px;
        margin: auto;
        img {
          height: 50px;
        }
      }
      .caption {
        display: block;
      }
    }
  }
}
.video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 99999;
  pointer-events: none;
  transition: all 1s;
  #full-screenVideo {
    z-index: 99999;
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
}

.hide {
  opacity: 0;
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.backgroundPopup {
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.popup {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  min-height: 500px;
  background: #fff;
  font-family: "proxima-nova";
  width: 100vw;
  height: 100vh;
  @media only screen and (min-width: 900px) {
    width: 60vw;
    max-width: 1100px;
    height: 60vh;
    min-height: 600px;
    max-height: 640px;
  }
  .storypopup {
    overflow: hidden;
    display: grid;
    height: 100%;
    h2 {
      font-family: "proxima-nova";
      font-weight: 700;
      margin: 0 auto 25px;
      text-align: center;
      align-self: center;
    }
    div {
      margin: 0 auto 10px;
      max-width: 523px;
      text-align: center;
      p {
        text-overflow: scroll;
        font-size: 17px;
        margin-top: 10px;
      }
    }
  }
  .inner-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    clear: both;
    @media only screen and (min-width: 900px) {
      position: relative;
    }

    .product-col-1 {
      @media only screen and (min-width: 900px) {
        float: left;
        clear: left;
        width: 55%;
      }
    }
    .product-col-2 {
      @media only screen and (min-width: 900px) {
        float: right;
        clear: right;
        width: 45%;
      }
    }

    .product-image-container {
      position: relative;
      margin: 15px 0;
      @media only screen and (min-width: 900px) {
        margin: 0;
      }
      .product-main-image-container {
        position: relative;
        @media only screen and (min-width: 600px) {
          padding-left: 0;
          width: 100%;
          margin-left: 0%;
        }
        .product-main-image {
          position: relative;
          max-width: 400px;
          margin: 0 auto;
          overflow: hidden;
        }
      }
    }

    .product-info_top {
      margin: 0;
      padding: 0;
      border-bottom: none;
      text-align: left;
      @media only screen and (min-width: 900px) {
        text-align: left;
        padding-bottom: 20px;
        margin-bottom: 16px;
      }
    }

    .product-info_product-details {
      margin: 20px 0;
      border-top: 1px solid #ccc;
      .tab-list {
        margin-top: 20px;
        display: flex;
        align-items: center;
        li {
          font-size: 12px;
          cursor: pointer;
          margin: 10px 10px 10px 0px;
          font-weight: bold;
          color: #ccc;
          &.active {
            color: #000;
          }
        }
      }
    }
  }
  .leftPart {
    float: left;
    height: 60vh;
    width: 49%;
    position: relative;
    border-right: 1px solid #ccc;
    img {
      max-height: 65vh;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
  .rightPart {
    width: 50%;
    float: left;
    color: #111;
    height: 60vh;
    box-sizing: border-box;
    padding: 10px 20px;
    display: grid;
  }
  .reference {
    color: #ccc;
  }
  h1 {
    display: block;
    margin-bottom: 0.4em;
    font-family: "proxima-nova", Helvetica, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: 0.025em;
    line-height: 1.2;
    color: #222;
    text-transform: none;
    margin-top: 0px;
    font-size: 24px;
  }
  .collection {
    display: block;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    color: #777;
  }
  .color {
    font-size: 12px;
    span {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .title {
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    color: #222;
    margin: 0;
  }
  .value {
    margin-top: 0;
  }
  .redButton {
    cursor: pointer;
    font-size: 20px;
    height: 57px;
    color: #fff;
    background: #ba0c2f;
    border: none;
    margin: 20px auto;
  }
  .addToCart {
    &:hover {
      background-color: #8a0923;
    }
  }
  .price {
    font-size: 20px;
  }
  .closeButton {
    position: absolute;
    top: 10px;
    right: 30px;
    color: #111;
    font-size: 40px;
    cursor: pointer;
  }
}
.product-specifications__list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  li {
    position: relative;
    width: 36%;
    padding-left: 50px;
    margin-bottom: 10px;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(0.5);
    }

    &.customDimension-Outside {
      &:before {
        content: url("../public/images/dimensions.png");
      }
    }
    &.customProductWeight {
      &:before {
        content: url("../public/images/poids.png");
      }
    }
    &.customMaterial {
      &:before {
        content: url("../public/images/materiaux.png");
      }
    }
  }
}

/* Cart */
.cartButton {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 2.4em;
  cursor: pointer;
}

.cartList {
  background: white;
  width: 375px;
  z-index: 99;
  overflow: hidden;
  &.mobile {
    right: 0;
    top: 0;
    border-radius: 0;
    &.open {
      width: 100%;
      height: 100%;
      button.close {
        right: 5vw;
        top: 3vw;
      }
    }
  }
  button.close {
    position: absolute;
    top: 0px;
    right: 1vw;
    background: none;
    font-size: 3em;
    border: none;
  }
  ul {
    margin: 20px;
    height: 80%;
    overflow-x: hidden;
    overflow-y: scroll;
    li.cartElement {
      padding: 20px;
      width: 100%;
      display: inline-block;
      img {
        width: 20%;
        float: left;
      }
      .content {
        width: 79%;
        float: left;
      }
    }
  }
}

/* Title */
h1.title {
  position: absolute;
  width: 80%;
  margin: 30px 10%;
  text-align: center;
  top: 10px;
  left: 0px;
  font-size: 1.5em;
  z-index: 10;
  line-height: 0.1em;
  &.mobile {
    margin-top: 100px;
  }
  &:before,
  &:after {
    background-color: #a91414;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 5%;
  }
  &:before {
    margin-right: 40px;
  }
  &:after {
    margin-left: 40px;
  }
}

.upperTitle {
  transform: none !important;
  display: block !important;
  width: 100%;
  div {
    width: 100%;
  }
}

.ImageMap {
  transform: none !important;
  display: block !important;
  width: 100%;
  div {
    width: 100%;
    img {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 10vw;
      height: 10vh;
      z-index: 30;
    }
    .mclarenZone {
      position: absolute;
      left: 130px;
      top: 130px;
      width: 50px;
      height: 50px;
      z-index: 31;
    }
  }
}
